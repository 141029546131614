import React from "react";
import { Switch, Route, Link } from 'react-router-dom'
import youtube from "../youtube"

class Videos extends React.Component {
    constructor(props) {
        super(props);

        var yt = localStorage.getItem('youtube');
        var num = 0;
        if (yt !== null) num = parseInt(yt);
        if (num % 20 === 0) {
            console.log('check youtube available');
            youtube.get("/channels", {
                params: { id: "UCpouHEh97MPsw_S0zbTQU2A" },
            }).then(x => {
                console.log(x);
                localStorage.setItem('youtube', 1); this.setState({ youtube: 1 });
            }).catch(err => { localStorage.setItem('youtube', -1); this.setState({ youtube: -1 }); });
        } else {
            if (isNaN(num)) num = 0; else num = num > 0 ? num + 1 : num - 1;
            localStorage.setItem('youtube', num);
        }
        this.state = { loaded: false, youtube: num, years: [], items: [], video: {}, };

    }

    componentDidMount() {
        fetch('/videos/videolist.json')
            .then(res => res.json())
            .then(
                (result) => {
                    var years = [];
                    for (const itm of result) {
                        let year = itm.Date.substring(0, 4);
                        if (years.filter(f => f === year).length === 0) { years.push(year); }
                    }
                    var v = result[0];
                    if (this.props.match.params.id) {
                        var rst = result.filter(f => f.Id === this.props.match.params.id);
                    }
                    this.setState({
                        loaded: true,
                        items: result,
                        years: years,
                        video: v,
                    });
                },
                (error) => { this.setState({ loaded: false }); }
            )
    }

    changeVideo() {
        this.setState({ youtube: -1 });
    }

    render() {
        const { loaded, youtube, items, years, video } = this.state;
        if (!loaded) return (<main role="main"> <div className="container py-2"><h3>Sorry, cannot load sermon videos.<br /> Please try again late.</h3></div></main>);

        return (
            <main role="main">
                <div className="container py-2">
                    <h3 className="display-4 d-none d-lg-inline">讲道录像 / SERMONS RECORDINGS</h3>
                    <h2 className="d-none d-md-inline  d-lg-none">讲道录像 / SERMONS RECORDINGS</h2>
                    <h3 className="d-inline d-md-none">讲道录像 / SERMONS RECORDINGS</h3>

                    <div className="alert alert-info my-2" role="alert">
                        {youtube > 0 ? (
                            <div> <i class="fab fa-youtube fa-lg"></i> 录像来自教会Youtube频道  <button type="button" onClick={this.changeVideo.bind(this)} class="btn btn-outline-primary ml-4"><i class="fas fa-reply"></i> 改到观看教会网站录像</button></div>

                        ) : (
                                <div><i className="fab fa-youtube fa-lg"></i> 录像来自教会网站</div>
                            )}
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <Switch>
                                <Route path="/videos/:id" children={<ViewVideo video={video} youtube={youtube} />} />
                                <Route path="/videos" children={<SelectVideo />} />
                            </Switch>
                        </div>
                        <div className="col-md-5">
                            <div id="accordion">
                                {years.map((year, idx) => (
                                    <div key={year} className="card">
                                        <div className="card-header">
                                            <button className="btn btn-link w-100 text-left" data-toggle="collapse" data-target={'#collapse' + year} aria-expanded="true">
                                                <b><i className="fas fa-video fa-lg"></i> {year}年讲道</b>
                                            </button>
                                        </div>

                                        <div id={'collapse' + year} className={idx === 0 ? 'collapse show' : 'collapse'} data-parent="#accordion">
                                            <div className="card-body list-group list-group-flush p-0 m-0">
                                                {items.filter(f => f.Date.substring(0, 4) === year).map(itm => (
                                                    <Link to={'/videos/' + itm.Id} onClick={() => { this.setState({ video: itm }) }} key={itm.Id} className="list-group-item list-group-item-action">
                                                        <div className="row">
                                                            <img className="col-md-4  d-none d-md-inline" alt={itm.Title} src={`http://renaichurch.com/videos/` + itm.ThumbnailUrl} />
                                                            <div className="col-md-8">
                                                                <b className="header">{itm.Title}</b>
                                                                <p>
                                                                    {itm.Date.substring(0, 10)}  | {itm.Description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}
export default Videos


function ViewVideo(props) {
    return (
        <div className="h-100" style={{ maxHeight: "420px", maxWidth: "660px", position: "sticky", top: "100px" }}>
            <iframe className="w-100 h-100" title="video player" src={props.youtube > 0 ? ('https://www.youtube.com/embed/' + props.video.Id) : ('http://renaichurch.com/videos' + props.video.VideoUrl)} allowFullScreen={true} />
        </div>
    )
}

function SelectVideo() {
    return (
        <div className="p-4"><h3>Please select a sermon to listen</h3>
            <img className=" img-fluid mx-auto" alt="" src="/images/family-worship.jpg" />
        </div>
    )
}
